/***********************
     POSITIONS
************************/

.m-position-top {
    top: 0
}

.m-position-bottom {
    bottom: 0
}

.m-position-right {
    right: 0
}

.m-position-left {
    left: 0
}


/***********************
     CONTAINER
************************/

.m-mapea-container {
    width: 100%;
    height: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAABlBMVEUAAAD///+l2Z/dAAAAAnRSTlMICLCRzwIAAAAVSURBVBjTY2CEAwYEGNKCw8QbCEEApbQAyTWv+7EAAAAASUVORK5CYII=);
    font-family: 'Muli', 'sans-serif' !important;
    font-size: 13px;
    color: #6C6C6C;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    -webkit-rtl-ordering: logical;
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
}


/***********************
     CONTROLES
************************/

.m-control {
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 990;
    overflow-y: hidden;
    background-color: rgba(255, 255, 255, .4);
    border-radius: 4px;
    padding: 2px;
}

.m-control>button {
    color: #404040;
}

div.m-control button[class^="g-cartografia-"],
div.m-control button[class*=" g-cartografia-"] {
    font-size: 28px;
    padding: 8px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0.75;
    -webkit-transition: opacity 0.25s ease 0s;
    transition: opacity 0.25s ease 0s;
    color: #404040;
    margin: 0;
}

.ol-overviewmap>button {
    opacity: 0.75;
    -webkit-transition: opacity 0.25s ease 0s;
    transition: opacity 0.25s ease 0s;
}

div.m-control.activated button[class^="g-cartografia-"],
div.m-control button[class^="g-cartografia-"]:hover,
div.m-control button[class*=" g-cartografia-"]:hover,
.m-areas>div.m-area>div.m-panel>button.m-panel-btn:hover,
.ol-overviewmap>button:hover {
    opacity: 1 !important;
}

div.m-control button[class^="g-cartografia-"]:focus,
div.m-control button[class*=" g-cartografia-"]:focus,
.m-areas>div.m-area>div.m-panel>button.m-panel-btn:focus {
    outline: none;
}

.m-help-btn,
.m-clear-btn {
    opacity: 0.4 !important;
    font-size: 18px !important;
}

.m-search-btn {
    order: 3;
    padding-right: 14px;
}

.m-help-btn {
    order: 2;
}

.m-clear-btn {
    order: 1;
}

.m-results-btn {
    display: none;
}


/***********************
     LABEL
************************/

.m-label-content {
    white-space: nowrap;
}


/***********************
     INPUTS
************************/


/* TEXT */

.m-mapea-container input[type="text"],
.m-mapea-container textarea {
    margin: 5px;
    padding: 5px;
    background-color: transparent;
    cursor: auto;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #6C6C6C;
    font-family: 'Muli', 'sans-serif' !important;
    font-size: 13px;
}

.m-mapea-container input[type="text"]:focus {
    outline: none;
    outline: none;
}

@media only screen and (max-width: 768px) {
    .m-wordbreak {
        word-break: break-all;
        white-space: none;
    }
}


/* BUTTON */

button::-moz-focus-inner {
    border: 0;
}


/***********************
     LOADING ANIMATION
************************/

@-moz-keyframes mloading {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes mloading {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes mloading {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/********************************/


/* correcciones tamaño relativo */


/*#map {
	width: 600px !important;
	height: 500px !important;
}*/

.ol-viewport,
.ol-overlaycontainer-stopevent,
.m-areas,
.m-area.m-top.m-right {
    height: inherit !important;
}

.m-panel.m-layerswitcher.opened {
    height: calc(100% - 30px);
}

.m-areas>div.m-area>div.m-panel.m-layerswitcher>div.m-panel-controls {
    height: 100% !important;
    max-height: 100%;
}

.m-attribution {
    left: 3px !important;
    right: inherit !important;
    z-index: 999999;
}

/* CNIG_CONFIG */
/* INCLUSIÓN TEMA CNIG */

/*** CUSTOM THEME COLORS ***/
/*** LIGHT BLUE from Navbar: #71A7D3 ***/
/*** DARK BLUE from Header: #364b5f ***/
/*** ORANGE from Links & h2: #f1832e ***/

/* buttons */

.m-areas>div.m-area>div.m-panel.collapsed>button.m-panel-btn,
.ol-zoom>button,
.m-areas>div.m-area>div.m-panel,
.ol-zoomslider>button.ol-zoomslider-thumb,
.ol-overviewmap.ol-collapsed,
.m-scale-container,
.m-wmcselector-container,
.m-areas>div.m-area>div.m-panel>div.m-panel-controls .m-mouse-position,
.m-areas>div.m-area>div.m-panel.m-geosearch>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-searchstreet>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-searchstreetgeosearch>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-tools>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-edition>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-printer>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-layerswitcher>button.m-panel-btn {
    background-color: #71A7D3 !important;
    opacity: 1;
}

/* white icons */

.m-areas>div.m-area>div.m-panel.m-geosearch.collapsed>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-searchstreet.collapsed>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-searchstreetgeosearch.collapsed>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-attributetable.collapsed>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-tools.collapsed>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-edition.collapsed>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-printer.collapsed>button.m-panel-btn,
.m-areas>div.m-area>div.m-panel.m-layerswitcher.collapsed>button.m-panel-btn,
.ol-zoom>button,
div.m-control button[class*=" g-cartografia-"],
div.m-control button[class^=g-cartografia-],
.ol-overviewmap.ol-collapsed>button,
.ol-overviewmap>button,
.m-wmcselector-select,
.m-wmcselector-container:before,
.m-mouse-position:before,
.m-mouse-position,
.m-scale-container>.m-unit:before,
.m-scale-container {
    color: #fff !important;
}

.m-left .m-panel.opened div.m-control button[class^=g-cartografia-] {
    color: #364b5f !important;
    background-color: white !important;
    opacity: 1;
}

/* secondary color */

.m-printer-container>div.button {
    background-color: #364b5f !important;
}

/* aditional colors */

.m-panel .title {
    color: #f1832e !important;
}

.ol-zoomslider>button.ol-zoomslider-thumb {
    border: 2px solid white;
}

.m-areas>div.m-area>div.m-panel.opened {
    background-color: #fff !important;
}

.m-wmcselector-select,
.m-areas>div.m-area>div.m-panel.m-scaleline {
    background-color: transparent !important;
}

/* corrections */

.m-areas>div.m-area>div.m-panel>div.m-panel-controls {
    border-radius: 0;
}

.m-areas>div.m-area>div.m-panel.opened.m-location,
.m-areas>div.m-area>div.m-panel.opened.m-rotate,
.m-areas>div.m-area>div.m-panel.opened.m-geosearchbylocation {
    background-color: #71A7D3 !important;
}

.layer .m-title {
    color: #6c6c6c !important;
}

.m-popup table {
    color: #6c6c6c !important;
}

.m-popup .m-content {
    min-width: 250px;
}

div.m-control button[class^="g-cartografia-"] {
    opacity: 1;
}

.ol-overviewmap:not(.ol-collapsed) button {
    background-color: #71A7D3 !important;
}

.m-control.m-getfeatureinfo-container.activated>button#m-getfeatureinfo-button {
    color: #71A7D3 !important;
}

.m-control.m-getfeatureinfo-container:not(.activated)>button#m-getfeatureinfo-button {
    color: #888888 !important;
}

.m-areas>div.m-area>div.m-panel.m-tools {
    order: -65;
}

.m-panel.m-layerswitcher.opened {
    height: calc(100% - 70px);
}

.m-areas>div.m-area>div.m-panel.m-layerswitcher>div.m-panel-controls {
    max-width: 33vw;
}

@media only screen and (max-width: 768px) {
    .m-popup .m-content {
        width: 100%;
    }

    .m-areas>div.m-area>div.m-panel.m-layerswitcher>div.m-panel-controls {
        max-width: 100vw;
    }

    .m-areas>div.m-area>div.m-panel.m-layerswitcher button.m-panel-btn.g-cartografia-flecha-derecha {
        color: #fff;
    }

}